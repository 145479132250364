.App {
  text-align: center;
  justify-content: center;
  background-color: #282c34;
  color: white;
}

.App-logo {
  width: 200px;
  border-radius: 50%;
}

.icon {
  padding-left: 10px;
  color: white;
}

.lkicon:hover {
  color: #0077B5;
}

.fbicon:hover {
  color: #3b5998;
}

.gmicon:hover {
  color: #ea4335;
}

.ghicon:hover {
  color: #f5f5f5;
}

.summary {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 30vh;
  overflow: auto;
}

.buttonContainer {
  overflow: hidden;
}

.skills {
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;

  width: 60%;
}

.skill {
  width: 45%;
  display: inline-block;
  padding-left: 20px;
  line-height: 2;
}

.buttom-gp {
  width: 10%;
  display: inline-block;
}

.App-header {

  /* min-height: 100vh; */
  /* display: flex;
  flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */

}

.App-link {
  color: #61dafb;
}

.icon-anchor {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
